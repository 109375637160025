<template>
  <div>
    <div style="height: 30px;margin: 25px 0;display: flex;align-items: center">
      <el-breadcrumb class="bread" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="breaditem"><span style="color: #909399">订货单 - 待完成</span></el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="selectdiv">
      <el-input class="family" size="small" v-model.trim="query.name" placeholder="供货商名称"
                @keyup.enter.native="selectOrder" clearable maxlength="20"
                style="width: 200px !important;margin-right: 10px">
      </el-input>
      <el-input class="family" size="small" v-model.trim="query.value"
                @keyup.enter.native="selectOrder" clearable maxlength="20"
                style="width: 300px !important;margin-right: 10px">
        <el-select class="family" size="small" slot="prepend" v-model="query.type"
                   @change="typeChange" style="width: 75px">
          <el-option value="isbn" label="书号"
                     style="font-family: 'Segoe UI', 'Roboto', arial, sans-serif;font-size: 13px"/>
          <el-option value="title" label="书名"
                     style="font-family: 'Segoe UI', 'Roboto', arial, sans-serif;font-size: 13px"/>
        </el-select>
      </el-input>
      <el-button type="primary" icon="el-icon-search" size="small" @click="selectOrder">查询</el-button>
    </div>
    <div class="selectdiv" style="margin-top: 30px">
      <el-select v-if="isGetBook" class="family" size="small" v-model="query.get" placeholder="代提状态"
                 clearable style="width: 100px;margin-right: 10px">
        <el-option value='0' label="非代提"
                   style="font-family: 'Segoe UI', 'Roboto', arial, sans-serif;font-size: 13px"/>
        <el-option value='1' label="代提"
                   style="font-family: 'Segoe UI', 'Roboto', arial, sans-serif;font-size: 13px"/>
      </el-select>
      <el-select class="family" size="small" v-model="query.pay" placeholder="结算方式"
                 clearable style="width: 100px;margin-right: 10px">
        <el-option value='0' label="日结"
                   style="font-family: 'Segoe UI', 'Roboto', arial, sans-serif;font-size: 13px"/>
        <el-option value='1' label="月结"
                   style="font-family: 'Segoe UI', 'Roboto', arial, sans-serif;font-size: 13px"/>
        <el-option value='2' label="预付"
                   style="font-family: 'Segoe UI', 'Roboto', arial, sans-serif;font-size: 13px"/>
      </el-select>
      <el-select class="family" size="small" v-model="query.account" placeholder="付款状态"
                 clearable style="width: 100px;margin-right: 10px">
        <el-option value='0' label="未付款"
                   style="font-family: 'Segoe UI', 'Roboto', arial, sans-serif;font-size: 13px"/>
        <el-option value='1' label="已付款"
                   style="font-family: 'Segoe UI', 'Roboto', arial, sans-serif;font-size: 13px"/>
      </el-select>
      <el-checkbox size="small" v-model="query.hide" label="非接单/已屏蔽" border style="margin-right: 0"/>
      <el-checkbox size="small" v-model="query.less" label="有缺货" border style="margin-right: 0"/>
      <el-checkbox v-if="isPayOrder" size="small" v-model="query.getpay" label="已代付" border style="margin-right: 0"/>
    </div>
    <div class="selectdiv" style="margin-top: 30px;margin-bottom: 47px">
      <el-button type="primary" size="small" icon="el-icon-download"
                 :loading="loadingDlo" @click="downloadOrderBatch">下载选中
      </el-button>
      <el-button type="success" size="small" icon="el-icon-edit"
                 @click="openCommentBatch">备注选中
      </el-button>
      <el-popover placement="bottom" v-model="visibleAcc">
        <div style="display: flex;justify-content: center;align-items: center">
          <span class="spanPopo">确定记账？</span>
          <el-input class="family" size="mini" v-model.trim="accCheck"
                    placeholder="防误操作，输入1234" maxlength="4"
                    @keyup.enter.native="updAccountBatch"
                    style="width: 170px !important">
          </el-input>
          <el-button class="buttonPopo" size="mini" type="success" @click="updAccountBatch"
                     icon="el-icon-check" :loading="loadingAcc"/>
        </div>
        <el-button slot="reference" type="warning" size="small" style="margin-left: 10px"
                   icon="el-icon-notebook-2" :loading="loadingAcc" @click="initAccCheck">记账选中
        </el-button>
      </el-popover>
      <el-popover placement="bottom" v-model="visibleCom">
        <div style="display: flex;justify-content: center;align-items: center">
          <span class="spanPopo">确定完成？</span>
          <el-input class="family" size="mini" v-model.trim="comCheck"
                    placeholder="防误操作，输入1234" maxlength="4"
                    @keyup.enter.native="updComplateBatch"
                    style="width: 170px !important">
          </el-input>
          <el-button class="buttonPopo" size="mini" type="success" @click="updComplateBatch"
                     icon="el-icon-check" :loading="loadingCom"/>
        </div>
        <el-button slot="reference" type="success" size="small"
                   style="margin-left: 10px" @click="initComCheck"
                   icon="el-icon-check" :loading="loadingCom">完成选中
        </el-button>
      </el-popover>
      <el-button type="text" size="samll" style="margin-left: 10px;height: 22px !important;"
                 icon="el-icon-document-checked" @click="getComplate">已完成订单
      </el-button>
    </div>
    <el-pagination background layout="prev,pager,next" :page-size="50" :total="total"
                   :current-page="query.page" @current-change="pageChange"
                   :hide-on-single-page="true" style="margin-top: 0;margin-bottom: 25px"/>
    <div v-if="total>0" style="height: 23px;line-height: 13px">
      <span class="span" style="color: #606266">已选 </span>
      <span class="span" style="color: darkorange">{{this.$refs.multipleTable.selection.length}}</span>
      <span class="span" style="color: #606266"> 条 (可分页)</span>

      <span class="span" style="color: #606266;margin-left: 30px">搜索出 </span>
      <span class="span" style="color: #409EFF">{{total}}</span>
      <span class="span" style="color: #606266"> 条结果</span>

      <span class="span" style="color: #606266;margin-left: 30px">订数 </span>
      <span class="span" style="color: #409EFF">{{countnum}}</span>
      <span class="span" style="color: #606266;margin-left: 30px">缺货 </span>
      <span class="span" style="color: #409EFF">{{countless}}</span>
      <span class="span" style="color: #606266;margin-left: 30px">书款 </span>
      <span class="span" style="color: #409EFF">{{parseFloat(countprice).toFixed(2)}}</span>
      <span class="span" style="color: #606266;margin-left: 30px">实付现款 </span>
      <span class="span" style="color: #EB6100">{{parseFloat(countpay).toFixed(2)}}</span>
    </div>
    <el-table border stripe size="small" :data="orders" :style="{width: '1000px'}"
              :header-cell-style="{background:'#ECF5FF',fontWeight: 'normal'}"
              :row-style="{height:'42px'}" @select="pinSelect" ref="multipleTable"
              :row-key="getRowKeys">
      <el-table-column type="selection" :reserve-selection="true" width="40px" key="0"
                       header-align="center" align="center"/>
      <el-table-column width="78" label="订单号" prop="id" :resizable="false" :formatter="idFormat"/>
      <el-table-column label="订货时间" prop="time" width="97" :resizable="false" :formatter="timeFormat"/>
      <el-table-column label="供货商" width="286" :resizable="false">
        <template slot-scope="scope">
          <div style="display: flex;flex-direction: column">
            <div style="float:left">
              <el-link class="link" :underline="false" style="margin-right: 5px"
                       @click="getSellerInfo(scope.row)">
                {{scope.row.seller.name}}
              </el-link>
              <svg v-if="scope.row.seller.qq!==undefined" class="icon" aria-hidden="true"
                   @click="openQQ(scope.row.seller.qq)"
                   style="cursor:pointer;font-size: 14px;margin-left: -2px;margin-right: 2px">
                <use xlink:href="#icon-qq1"></use>
              </svg>
              <el-tag type="warning">{{payFormat(scope.row.seller.pay)}}</el-tag>
              <el-popover v-if="scope.row.getBook && scope.row.getAdmin===undefined"
                          placement="right" v-model="scope.row.visible">
                <div style="display: flex;justify-content: center;align-items: center">
                  <span class="span1">取消代提？</span>
                  <el-button class="buttonPopo" size="mini" type="success"
                             @click="updGetBook(scope.row)" icon="el-icon-check"/>
                </div>
                <el-tag slot="reference" type="primary" style="cursor:pointer">代提</el-tag>
              </el-popover>
              <el-tag v-if="scope.row.getBook && scope.row.getAdmin!==undefined" type="primary">
                {{'代提('+scope.row.getAdmin+')'}}
              </el-tag>
              <el-tag v-if="!scope.row.getBook" type="primary">{{getFormat(scope.row.seller.getmode)}}
              </el-tag>
              <el-tag v-if="!scope.row.hide && !scope.row.prepare && scope.row.seller.online" type="info">接单</el-tag>
              <el-tag v-if="scope.row.hide" type="danger">已屏蔽</el-tag>
              <el-tag v-if="scope.row.prepare" type="success">已配货</el-tag>
            </div>
            <span v-if="scope.row.comment!==undefined && scope.row.comment!==''"
                  class="comment">网店备注：{{scope.row.comment}}</span>
            <span v-if="scope.row.scomment!==undefined && scope.row.scomment!==''"
                  class="scomment">供货商备注：{{scope.row.scomment}}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="订数" prop="orderNum" width="55" :resizable="false"/>
      <el-table-column label="缺货" prop="lessNum" width="55" :resizable="false"
                       :formatter="lessNumFormat"/>
      <el-table-column width="70" :resizable="false">
        <template slot="header">
          <div style="float: left">
            书款
            <el-tooltip placement="top-start" :enterable="false" effect="light">
              <div slot="content">
                已扣减缺货书款
              </div>
              <i class="el-icon-info" style="color: #C0C4CC"/>
            </el-tooltip>
          </div>
        </template>
        <template slot-scope="scope">
          <div style="display: flex;flex-direction: column">
            <span v-if="scope.row.orderOriPrice>scope.row.orderPrice"
                  style="color: #C0C4CC;text-decoration: line-through">
                {{orderPriceFormat(scope.row.orderOriPrice)}}
            </span>
            <span>{{orderPriceFormat(scope.row.orderPrice)}}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column width="145" :resizable="false">
        <template slot="header">
          <div style="float: left">
            付款信息
            <el-tooltip placement="top-start" :enterable="false" effect="light">
              <div slot="content">
                实付现款：不计入账本，可作为订单付款记录，平台可代付<br><br>
                退货抵扣 / 退货记账：计入账本<br><br>
                <span style="color: #C0C4CC">
                  * 批量记账只支持月结、预付供货商，以默认书款记账<br>
                  * 付款信息设置后无法修改<br>
                  * 网店需管理员授权开通，且供货商支持，订单才可代付</span>
              </div>
              <i class="el-icon-info" style="color: #C0C4CC"/>
            </el-tooltip>
          </div>
        </template>
        <template slot-scope="scope">
          <el-link v-if="!scope.row.account" class="link" :underline="false"
                   @click="openPay(scope.row)" style="color:#C0C4CC">设置
          </el-link>
          <div v-if="scope.row.account">
            <div v-if="scope.row.cashPrice>0">
              <el-tag type="warning">现款</el-tag>
              <span style="color: #EB6100;margin-left: 5px">{{priceFormat(scope.row.cashPrice)}}</span>
              <el-link v-if="scope.row.payId===undefined && scope.row.seller.alipayName==='1' && isPayOrder"
                       class="link" :underline="false"
                       @click="openGetPay(scope.row)" style="margin-left: 10px;color:#C0C4CC">代付
              </el-link>
              <el-tag v-if="scope.row.payId!==undefined" type="success"
                      style="margin-left: 5px;cursor:pointer" @click="openImg(scope.row)">已代付
              </el-tag>
            </div>
            <div v-if="scope.row.accountPrice>0">
              <el-tag type="warning">{{scope.row.seller.pay==='0'?'抵扣':'记账'}}</el-tag>
              <span style="color: #EB6100;margin-left: 5px">{{priceFormat(scope.row.accountPrice)}}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="明细 / 付款码 / 备注 / 订单" width="173" :resizable="false"
                       header-align="center">
        <template slot-scope="scope">
          <div class="scope">
            <el-button class="buttonTable" size="mini" type="primary" icon="el-icon-search"
                       @click="getDetail(scope.row)"/>
            <el-button :disabled="!scope.row.seller.alipay && !scope.row.seller.wechat"
                       :type="(!scope.row.seller.alipay && !scope.row.seller.wechat)?'info':'warning'"
                       :plain="(!scope.row.seller.alipay && !scope.row.seller.wechat)"
                       class="buttonTable" size="mini" icon="el-icon-picture-outline" @click="openCode(scope.row)"/>
            <el-button class="buttonTable" size="mini" type="success" icon="el-icon-edit"
                       @click="openComment(scope.row)"/>
            <el-button class="buttonTable" size="mini" type="primary" icon="el-icon-download"
                       @click="downloadOrder(scope.row)" :loading="scope.row.loadingDlo"/>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev,pager,next" :page-size="50" :total="total"
                   :current-page="query.page" @current-change="pageChange"
                   :hide-on-single-page="true"/>
    <el-dialog :visible.sync="visiblePay" custom-class="dialogPay" append-to-body
               :close-on-click-modal="true">
      <div v-if="data!==undefined" style="display: flex;flex-direction: column;width: 350px">
        <div style="display: flex;justify-content: center;height: 41px;
        background-color: #ECF5FF;margin-bottom: 20px">
          <span style="font-size: 13px;line-height: 23px;margin-top: 9px;color: #909399;margin-right: 30px">
            {{data.seller.name}}</span>
          <span
            style="font-size: 13px;line-height: 23px;margin-top: 9px;color: #EB6100">书款 {{priceFormat(data.orderPrice)}}</span>
        </div>
        <div style="display: flex;justify-content: center;height: 128px">
          <div style="display: flex;flex-direction: column">
            <div v-if="data.seller.pay==='0'" style="display: flex;align-items: center;margin-bottom: 10px">
              <span style="font-size: 12px;color: #409EFF;margin-right: 10px">实付现款</span>
              <el-input-number class="family1" size="mini" v-model="data.cashPrice"
                               :controls="false" :precision="2" :min="0" :max="9999.99"/>
              <el-link class="link" :underline="false" @click="comPay(data)"
                       style="color: #EB6100;margin-left: 10px">计算书款减抵扣
              </el-link>
            </div>
            <div style="display: flex;align-items: center;margin-bottom: 10px">
              <span style="font-size: 12px;color: #409EFF;margin-right: 10px">
                {{data.seller.pay==='0'?'退货抵扣':'订货记账'}}</span>
              <el-input-number class="family1" size="mini" v-model="data.accountPrice"
                               :controls="false" :precision="2" :min="0" :max="9999.99"
                               :disabled="data.seller.pay==='0' && balance<=0"/>
              <span style="font-size: 12px;color: #409EFF;margin-left: 10px">账本余额</span>
              <el-link style="font-size: 12px;color:#606266;margin-left: 5px"
                       @click="getAccount(data)">
                {{priceFormat(balance)}}
              </el-link>
            </div>
            <el-popconfirm
              v-if="(data.cashPrice===undefined?0:data.cashPrice)+(data.accountPrice===undefined?0:data.accountPrice)!==data.orderPrice"
              title="付款金额与书款不一致，确定保存？" @confirm="updAccount(data)">
              <el-button slot="reference" type="success" size="mini"
                         style="margin-left: 58px;width: 85px"
                         icon="el-icon-check" :loading="data.loading">保存
              </el-button>
            </el-popconfirm>
            <el-button
              v-if="(data.cashPrice===undefined?0:data.cashPrice)+(data.accountPrice===undefined?0:data.accountPrice)===data.orderPrice"
              @click="updAccount(data)" type="success" size="mini" icon="el-icon-check"
              style="margin-left: 58px;width: 85px" :loading="data.loading">保存
            </el-button>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="visibleGetPay" custom-class="dialogPay" append-to-body
               :close-on-click-modal="false">
      <div v-if="data!==undefined" style="display: flex;flex-direction: column;width: 350px">
        <div style="display: flex;justify-content: center;height: 41px;
        background-color: #ECF5FF;margin-bottom: 20px">
          <span style="font-size: 13px;line-height: 23px;margin-top: 9px;color: #909399">
            {{data.seller.name}}</span>
        </div>
        <div style="display: flex;justify-content: center;height: 128px">
          <div style="display: flex;flex-direction: column">
            <div style="display: flex;align-items: center;justify-content: center;
            margin-bottom: 20px;margin-top: 10px">
              <span style="font-size: 12px;color: #409EFF">代付书款</span>
              <span style="font-size: 13px;color: #EB6100;margin-left: 5px">¥</span>
              <span style="font-size: 18px;color: #EB6100;margin-left: 3px">{{priceFormat(data.cashPrice)}}</span>
              <span style="font-size: 12px;color: #409EFF;margin-left: 20px">账户余额</span>
              <el-link style="font-size: 12px;color:#606266;margin-left: 5px"
                       @click="getBookAccount">
                {{priceFormat(balance)}}
              </el-link>
            </div>
            <div>
              <el-popconfirm title="确定代付？" @confirm="payOrder(data)">
                <el-button slot="reference" type="success" size="mini"
                           style="width: 100px" icon="el-icon-check" :loading="data.loading">
                  {{data.loading?'支付中…':'代付'}}
                </el-button>
              </el-popconfirm>
              <el-button v-if="!data.loading" size="mini" @click="closeGetPay"
                         style="width: 60px;margin-left: 50px">取消
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="visibleCommentBatch" custom-class="dialogPay" append-to-body
               :close-on-click-modal="true">
      <div style="display: flex;flex-direction: column;width: 350px">
        <div style="display: flex;justify-content: center;height: 41px;
        background-color: #ECF5FF">
          <span style="font-size: 13px;line-height: 23px;margin-top: 9px;color: #909399;">
            设置选中订单备注</span>
        </div>
        <div style="display: flex;justify-content: center;align-items: center;height: 148px">
          <div>
            <div style="display: flex;align-items: start;margin-bottom: 10px">
              <el-input class="family1" size="mini" v-model.trim="comment"
                        type="textarea" :rows="3" style="width: 270px" maxlength="100"
                        resize="none" placeholder="订单备注，供货商端可见"
                        @keyup.enter.native="updCommentBatch"/>
            </div>
            <el-button @click="updCommentBatch" type="success" size="mini"
                       style="width: 85px" icon="el-icon-check" :loading="loadingCommentBatch">保存
            </el-button>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="visibleComment" custom-class="dialogPay" append-to-body
               :close-on-click-modal="true">
      <div v-if="data!==undefined" style="display: flex;flex-direction: column;width: 350px">
        <div style="display: flex;justify-content: center;height: 41px;
        background-color: #ECF5FF">
          <span style="font-size: 13px;line-height: 23px;margin-top: 9px;color: #909399;">
            {{idFormat(data)+' - '+data.seller.name}}</span>
        </div>
        <div style="display: flex;justify-content: center;align-items: center;height: 148px">
          <div>
            <div style="display: flex;align-items: start;margin-bottom: 10px">
              <el-input class="family1" size="mini" v-model.trim="comment"
                        type="textarea" :rows="3" style="width: 270px" maxlength="100"
                        resize="none" placeholder="订单备注，供货商端可见"
                        @keyup.enter.native="updComment(data)"/>
            </div>
            <el-button @click="updComment(data)" type="success" size="mini"
                       style="width: 85px" icon="el-icon-check">保存
            </el-button>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="visibleCode" custom-class="dialogCode" append-to-body
               :close-on-click-modal="true">
      <div style="display: flex;flex-direction: column;width: 470px">
        <div style="display: flex;justify-content: center;height: 41px;background-color: #ECF5FF">
          <span style="font-size: 13px;line-height: 23px;margin-top: 9px;color: #909399;margin-right: 30px">{{sellerName}}</span>
          <span
            style="font-size: 13px;line-height: 23px;margin-top: 9px;color: #EB6100">书款 {{priceFormat(orderPrice)}}</span>
        </div>
        <div style="display: flex;justify-content: center;height: 360px">
          <el-image v-if="alipay!==''" style="width: 235px; height: 360px" :src="alipay" fit="cover"/>
          <el-image v-if="wechat!==''" style="width: 235px; height: 360px" :src="wechat" fit="cover"/>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="visibleImg" custom-class="dialogImg" append-to-body
               :close-on-click-modal="true">
      <el-image style="width: 100%;height: 100%" :src="payImg" fit="cover" @load="openImgSuc" @error="openImgErr">
        <div slot="placeholder">
          <i class="el-icon-loading"></i>
          <span style="font-size: 14px;color: #909399">加载中…</span>
        </div>
        <div slot="error">
          <span style="font-size: 14px;color: #909399">付款凭证暂未生成，请稍后查看…</span>
        </div>
      </el-image>
      <div v-if="openImgSuccess" style="height: 35px;display: flex;justify-content: center">
        <el-button type="primary" icon="el-icon-copy-document" size="mini"
                   :loading="copyImgLoading" @click="copyImg" style="height: 22px !important;">复制凭证
        </el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="visibleInf" custom-class="dialogInf" append-to-body>
      <div style="display: flex;flex-direction: column;width: 310px;height: 200px">
        <div class="infodiv">
          <span class="title">供货商：</span>
          <span class="cont">{{infoFormat(sellerInfo.name)}}</span>
        </div>
        <el-divider/>
        <div class="infodiv">
          <span class="title">联系人：</span>
          <span class="cont" :style="{color:sellerInfo.contact===undefined?'#C0C4CC':'#606266'}">
            {{infoFormat(sellerInfo.contact)}}</span>
        </div>
        <el-divider/>
        <div class="infodiv">
          <span class="title">手机号：</span>
          <span class="cont" :style="{color:sellerInfo.mobile===undefined?'#C0C4CC':'#606266'}">
            {{infoFormat(sellerInfo.mobile)}}</span>
        </div>
        <el-divider/>
        <div class="infodiv">
          <span class="title">qq号：</span>
          <span class="cont" :style="{color:sellerInfo.qq===undefined?'#C0C4CC':'#606266'}">
            {{infoFormat(sellerInfo.qq)}}</span>
        </div>
        <el-divider/>
        <div class="infodiv">
          <span class="title">地址：</span>
          <span class="cont" :style="{color:sellerInfo.address===undefined?'#C0C4CC':'#606266'}">
            {{infoFormat(sellerInfo.address)}}</span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    created () {
      this.getBuyerGetInfo()

      this.id = sessionStorage.getItem('orderbackId')
      if (this.id !== null) {
        sessionStorage.clear()
        this.getOrder(this.id)
      } else {
        this.getOrderList()
      }
    },
    mounted () {
      window.addEventListener('keydown', code => {
        if (code.keyCode === 16 && code.shiftKey) {
          this.pin = true
        }
      })
      window.addEventListener('keyup', code => {
        if (code.keyCode === 16) {
          this.pin = false
        }
      })
    },
    data () {
      return {
        query: {
          name: '',
          type: 'isbn',
          value: '',
          pay: '',
          account: '',
          get: '',
          hide: false,
          less: false,
          getpay: false,
          page: 1
        },
        orders: [],
        total: 0,
        countnum: 0,
        countless: 0,
        countprice: 0,
        countpay: 0,
        isGetBook: false,
        isPayOrder: false,

        loadingDlo: false,
        visibleAcc: false,
        loadingAli: false,
        visibleAli: false,
        loadingAcc: false,
        visibleCom: false,
        loadingCom: false,
        visibleCommentBatch: false,
        loadingCommentBatch: false,

        visibleComment: false,
        visibleCode: false,
        visibleImg: false,
        visiblePay: false,
        visibleGetPay: false,
        data: undefined,

        id: null,
        sellerName: '',
        orderPrice: 0,
        alipay: '',
        wechat: '',
        qq: '',
        payTime: 0,
        payId: '',
        payImg: '',
        balance: 0,
        comment: '',
        openImgSuccess: false,
        copyImgLoading: false,

        origin: -1,
        pin: false,
        accCheck: undefined,
        comCheck: undefined,

        sellerInfo: {},
        visibleInf: false
      }
    },
    methods: {
      idFormat (row) {
        return '#' + (Array(7).join('0') + row.id).slice(-7)
      },
      timeFormat (row) {
        const date = new Date(row.time)
        let MM = date.getMonth() + 1
        MM = MM < 10 ? ('0' + MM) : MM
        let d = date.getDate()
        d = d < 10 ? ('0' + d) : d
        let h = date.getHours()
        h = h < 10 ? ('0' + h) : h
        let m = date.getMinutes()
        m = m < 10 ? ('0' + m) : m
        return MM + '-' + d + '　' + h + ':' + m
      },
      payFormat (value) {
        if (value === '0') {
          return '日结'
        } else if (value === '1') {
          return '月结'
        } else if (value === '2') {
          return '预付'
        }
      },
      getFormat (value) {
        if (value === '0') {
          return '自提'
        } else if (value === '1') {
          return '兴茂'
        } else if (value === '2') {
          return '代发'
        } else {
          return '书店宝'
        }
      },
      lessNumFormat (row) {
        if (row.lessNum > 0) {
          return row.lessNum
        }
      },
      orderPriceFormat (value) {
        if (value > 0) {
          return parseFloat(value).toFixed(2)
        }
      },
      priceFormat (value) {
        return parseFloat(value).toFixed(2)
      },
      getRowKeys (row) {
        return row.id
      },

      typeChange () {
        this.query.value = ''
      },
      pinSelect (item, index) {
        const data = this.$refs.multipleTable.tableData // 获取所以数据
        const origin = this.origin // 起点数 从-1开始
        const endIdx = index.index // 终点数
        if (this.pin && item.includes(data[origin])) { // 判断按住
          const sum = Math.abs(origin - endIdx) + 1// 这里记录终点
          const min = Math.min(origin, endIdx)// 这里记录起点
          let i = 0
          while (i < sum) {
            const index = min + i
            this.$refs.multipleTable.toggleRowSelection(data[index], true) // 通过ref打点调用toggleRowSelection方法，第二个必须为true
            i++
          }
        } else {
          this.origin = index.index // 没按住记录起点
        }
      },
      selectOrder () {
        this.$refs.multipleTable.clearSelection()
        this.query.page = 1
        this.getOrderList()
      },
      pageChange (page) {
        this.query.page = page
        this.getOrderList()
      },

      async getOrder (id) {
        const loading = this.loading()

        try {
          const { data: res } = await this.$http.get('getOrder', { params: { id: id } })

          loading.close()

          if (!this.isLogin(res)) {
            return
          }
          if (res.code !== 0) {
            this.total = 0
            this.orders = []
            this.$msg.warning(res.msg)
            return
          }

          this.total = res.data.total
          this.countnum = res.data.count.orderNum
          this.countless = res.data.count.lessNum
          this.countprice = res.data.count.orderPrice
          this.countpay = res.data.count.cashPrice
          this.orders = res.data.data
          this.orders = this.orders.map(item => {
            this.$set(item, 'loading', false)
            this.$set(item, 'loadingDlo', false)
            return item
          })
          this.orders.forEach((item, index) => {
            item.index = index
          })

          document.documentElement.scrollTop = document.body.scrollTop = 0
        } catch (e) {
          this.total = 0
          this.orders = []
          loading.close()
          this.$msg.error(this.netError)
        }
      },
      async getOrderList () {
        const loading = this.loading()

        try {
          const { data: res } = await this.$http.get('getOrderList',
            { params: this.query })

          loading.close()

          if (!this.isLogin(res)) {
            return
          }
          if (res.code !== 0) {
            this.total = 0
            this.orders = []
            this.$msg.warning(res.msg)
            return
          }

          this.total = res.data.total
          this.countnum = res.data.count.orderNum
          this.countless = res.data.count.lessNum
          this.countprice = res.data.count.orderPrice
          this.countpay = res.data.count.cashPrice
          this.orders = res.data.data
          this.orders = this.orders.map(item => {
            this.$set(item, 'loading', false)
            this.$set(item, 'loadingDlo', false)
            return item
          })
          this.orders.forEach((item, index) => {
            item.index = index
          })

          document.documentElement.scrollTop = document.body.scrollTop = 0
        } catch (e) {
          this.total = 0
          this.orders = []
          loading.close()
          this.$msg.error(this.netError)
        }
      },
      async downloadOrderBatch () {
        if (this.$refs.multipleTable.selection.length === 0) {
          this.$msg.warning('请选择要下载的订单！')
          return
        }
        this.loadingDlo = true

        try {
          const orders = []
          for (let i = 0; i < this.$refs.multipleTable.selection.length; i++) {
            orders.push({ id: this.$refs.multipleTable.selection[i].id })
          }

          const res = await this.$http.post('downloadOrderBatch',
            orders, { responseType: 'blob' })

          this.loadingDlo = false

          if (res.headers['content-type'] !== ('application/zip')) {
            const reader = new FileReader()
            reader.readAsText(res.data)
            reader.onload = e => {
              const data = JSON.parse(e.target.result)
              if (!this.isLogin(data)) {
                return
              }
              this.$msg.warning(data.msg)
            }
            return
          }

          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.download = this.nowDate() + ' 订货单.zip'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          window.URL.revokeObjectURL(url)
          this.$refs.multipleTable.clearSelection()
          this.$msg.success('下载成功！')
        } catch (e) {
          this.loadingDlo = false
          this.$msg.error(this.netError)
        }
      },
      async downloadOrder (row) {
        this.$set(row, 'loadingDlo', true)

        try {
          const res = await this.$http.get('downloadOrder',
            {
              responseType: 'blob',
              params: {
                id: row.id
              }
            })

          this.$set(row, 'loadingDlo', false)

          if (res.headers['content-type'] !== ('application/xlsx')) {
            const reader = new FileReader()
            reader.readAsText(res.data)
            reader.onload = e => {
              const data = JSON.parse(e.target.result)
              if (!this.isLogin(data)) {
                return
              }
              this.$msg.warning(data.msg)
            }
            return
          }

          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.download = this.dateFormat(row.time) + ' • ' + this.idFormat(row) + ' • ' + window.localStorage.getItem('name') + ' • ' + row.seller.name + '.xlsx'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          window.URL.revokeObjectURL(url)
          this.$msg.success('下载成功！')
        } catch (e) {
          console.log(e)
          this.$set(row, 'loadingDlo', false)
          this.$msg.error(this.netError)
        }
      },
      async updCommentBatch () {
        this.loadingCommentBatch = true
        try {
          const orders = []
          for (let i = 0; i < this.$refs.multipleTable.selection.length; i++) {
            orders.push({ id: this.$refs.multipleTable.selection[i].id })
          }

          const { data: res } = await this.$http.post(
            'updOrderCommentBatch', orders, { params: { comment: this.comment } })

          this.loadingCommentBatch = false

          if (!this.isLogin(res)) {
            return
          }
          if (res.code !== 0) {
            return this.$msg.warning(res.msg)
          }

          this.visibleCommentBatch = false
          this.selectOrder()
          this.$msg.success('备注成功！')
        } catch (e) {
          this.loadingCommentBatch = false
          this.$msg.error(this.netError)
        }
      },
      async updAccountBatch () {
        if (this.accCheck !== '1234') {
          this.$msg.warning('输入1234后才可记账！')
          return
        }
        this.visibleAcc = false
        if (this.$refs.multipleTable.selection.length === 0) {
          this.$msg.warning('请选择要记账的订单！')
          return
        }
        this.loadingAcc = true

        try {
          const orders = []
          for (let i = 0; i < this.$refs.multipleTable.selection.length; i++) {
            orders.push({ id: this.$refs.multipleTable.selection[i].id })
          }

          const { data: res } = await this.$http.post('updOrderAccountBatch', orders)
          this.loadingAcc = false

          if (!this.isLogin(res)) {
            return
          }
          if (res.code !== 0) {
            return this.$msg.warning(res.msg)
          }

          this.selectOrder()
          this.$msg.success('记账成功！')
        } catch (e) {
          this.loadingAcc = false
          this.$msg.error(this.netError)
        }
      },
      async updComplateBatch () {
        if (this.comCheck !== '1234') {
          this.$msg.warning('输入1234后才可完成！')
          return
        }
        this.visibleCom = false
        if (this.$refs.multipleTable.selection.length === 0) {
          this.$msg.warning('请选择要完成的订单！')
          return
        }
        this.loadingCom = true

        try {
          const orders = []
          for (let i = 0; i < this.$refs.multipleTable.selection.length; i++) {
            orders.push({ id: this.$refs.multipleTable.selection[i].id })
          }

          const { data: res } = await this.$http.post('updOrderComplateBatch', orders)

          this.loadingCom = false

          if (!this.isLogin(res)) {
            return
          }
          if (res.code !== 0) {
            return this.$msg.warning(res.msg)
          }

          this.selectOrder()
          this.$msg.success('操作成功！')
        } catch (e) {
          this.loadingCom = false
          this.$msg.error(this.netError)
        }
      },
      getComplate () {
        this.$router.push({
          name: 'complateorder'
        })
      },

      async openPay (row) {
        try {
          this.balance = 0
          row.cashPrice = 0
          row.accountPrice = 0

          const { data: res } = await this.$http.get('getPayInfo',
            {
              params:
                {
                  sellerId: row.seller.id,
                  id: row.id
                }
            })

          if (!this.isLogin(res)) {
            return
          }
          if (res.code !== 0) {
            this.$msg.warning(res.msg)
            return
          }

          row.lessNum = res.data.data.lessNum
          row.orderPrice = res.data.data.orderPrice

          if (row.seller.pay === '0') {
            row.cashPrice = row.orderPrice
          } else {
            row.accountPrice = row.orderPrice
          }

          this.balance = res.data.count
          this.data = row
          this.visiblePay = true
        } catch (e) {
          this.$msg.error(this.netError)
        }
      },
      comPay (data) {
        data.cashPrice = data.orderPrice - data.accountPrice
      },
      async openCode (row) {
        this.sellerName = row.seller.name
        this.orderPrice = row.orderPrice
        this.alipay = ''
        this.wechat = ''
        if (row.seller.alipay) {
          this.alipay = this.codeURL + 'alipay_' + row.seller.id + '.jpg?' + new Date()
        }
        if (row.seller.wechat) {
          this.wechat = this.codeURL + 'wechat_' + row.seller.id + '.jpg?' + new Date()
        }
        this.visibleCode = true
        try {
          await this.$http.get('openPayCode')
        } catch (e) {
        }
      },
      async openImg (row) {
        this.payImg = this.imageURL + row.payId + '.png?' + new Date()
        this.qq = row.seller.qq
        this.payTime = row.payTime
        this.payId = row.payId
        this.openImgSuccess = false
        this.copyImgLoading = false
        this.visibleImg = true
      },
      openImgSuc () {
        this.openImgSuccess = true
      },
      openImgErr () {
        if ((new Date() - new Date(this.payTime)) / 1000 > 1800) {
          this.getPayImg()
          this.$msg.success('已再次申请付款凭证，请稍后尝试查看！')
        }
      },
      async copyImg () {
        try {
          this.copyImgLoading = true
          const data = await fetch(this.payImg)
          const blob = await data.blob()
          await navigator.clipboard.write([
            new window.ClipboardItem({ [blob.type]: blob })
          ]).then(
            this.openQQ(this.qq),
            this.visibleImg = false,
            this.copyImgLoading = false,
            this.$msg.success('复制凭证成功！')
          )
        } catch (e) {
          this.copyImgLoading = false
          this.$msg.warning('复制凭证失败！')
        }
      },
      openComment (row) {
        this.data = row
        this.comment = row.comment === undefined ? '' : row.comment
        this.visibleComment = true
      },
      openCommentBatch () {
        if (this.$refs.multipleTable.selection.length === 0) {
          this.$msg.warning('请选择要备注的订单！')
          return
        }
        this.comment = ''
        this.visibleCommentBatch = true
      },
      async openQQ (qq) {
        if (qq !== undefined) {
          location.href = 'tencent://message/?uin=' + qq
          try {
            await this.$http.get('openQQ')
          } catch (e) {
          }
        }
      },

      async openGetPay (row) {
        try {
          this.balance = 0
          const { data: res } = await this.$http.get('getBookBalance')

          if (!this.isLogin(res)) {
            return
          }
          if (res.code !== 0) {
            this.$msg.warning(res.msg)
            return
          }

          this.balance = res.data
          this.data = row
          this.visibleGetPay = true
        } catch (e) {
          this.$msg.error(this.netError)
        }
      },
      closeGetPay () {
        this.visibleGetPay = false
      },
      async payOrder (row) {
        if (this.balance < row.cashPrice) {
          this.$msg.warning('代付失败，书款账户余额不足！')
          return
        }

        this.$set(row, 'loading', true)

        try {
          const { data: res } = await this.$http.get('payOrder',
            {
              params: {
                id: row.id
              }
            })
          this.$set(row, 'loading', false)

          if (!this.isLogin(res)) {
            return
          }
          if (res.code !== 0) {
            return this.$msg.warning(res.msg)
          }

          this.visibleGetPay = false
          this.$set(row, 'payId', res.data)
          this.$msg.success('代付成功！')
        } catch (e) {
          this.$set(row, 'loading', false)
          this.$msg.error(this.netError)
        }
      },
      async getPayImg () {
        try {
          await this.$http.get('getPayImg',
            {
              params: {
                payId: this.payId
              }
            })
        } catch (e) {
        }
      },

      async updAccount (row) {
        if (row.cashPrice === undefined) {
          row.cashPrice = 0
        }
        if (row.accountPrice === undefined) {
          row.accountPrice = 0
        }
        if (row.cashPrice === 0 && row.accountPrice === 0) {
          this.$msg.warning('金额不能为0！')
          return
        }

        this.$set(row, 'loading', true)

        try {
          const { data: res } = await this.$http.get('updOrderAccount',
            {
              params: {
                id: row.id,
                cashPrice: row.cashPrice,
                accountPrice: row.accountPrice
              }
            })
          this.$set(row, 'loading', false)

          if (!this.isLogin(res)) {
            return
          }
          if (res.code !== 0) {
            return this.$msg.warning(res.msg)
          }

          this.visiblePay = false
          this.$set(row, 'account', true)
          this.$msg.success('设置成功！')
        } catch (e) {
          this.$set(row, 'loading', false)
          this.$msg.error(this.netError)
        }
      },
      async updComment (row) {
        try {
          const { data: res } = await this.$http.get('updOrderComment',
            {
              params: {
                id: row.id,
                comment: this.comment
              }
            })

          if (!this.isLogin(res)) {
            return
          }
          if (res.code !== 0) {
            return this.$msg.warning(res.msg)
          }

          this.visibleComment = false
          this.$set(row, 'comment', this.comment)
          this.$msg.success('备注成功！')
        } catch (e) {
          this.$msg.error(this.netError)
        }
      },
      async updGetBook (row) {
        try {
          const { data: res } = await this.$http.get('updOrderGetBook',
            {
              params: {
                id: row.id
              }
            })

          if (!this.isLogin(res)) {
            return
          }
          if (res.code !== 0) {
            return this.$msg.warning(res.msg)
          }

          this.$set(row, 'getBook', false)
          this.$msg.success('取消成功！')
        } catch (e) {
          this.$msg.error(this.netError)
        }
      },
      getDetail (row) {
        sessionStorage.setItem('id', row.id)
        sessionStorage.setItem('type', this.query.type)
        sessionStorage.setItem('value', this.query.value)
        sessionStorage.setItem('name', row.seller.name)
        const route = this.$router.resolve({ name: 'detail' })
        window.open(route.href, '_blank')
      },
      getAccount (row) {
        sessionStorage.setItem('id', row.seller.id)
        sessionStorage.setItem('name', row.seller.name)
        sessionStorage.setItem('alipay', row.seller.alipay)
        sessionStorage.setItem('wechat', row.seller.wechat)
        const route = this.$router.resolve({ name: 'selleraccount' })
        window.open(route.href, '_blank')
      },
      getBookAccount () {
        const route = this.$router.resolve({ name: 'systembookaccount' })
        window.open(route.href, '_blank')
      },

      async getBuyerGetInfo () {
        try {
          const { data: res } = await this.$http.get('getBuyerGetInfo')

          if (!this.isLogin(res)) {
            return
          }
          if (res.code !== 0) {
            this.$msg.warning(res.msg)
          } else {
            this.isGetBook = res.data.getBook
            this.isPayOrder = res.data.payOrder
          }
        } catch (e) {
          this.$msg.error(this.netError)
        }
      },
      nowDate () {
        const date = new Date()
        return (date.getMonth() + 1) + '-' + date.getDate()
      },
      dateFormat (time) {
        const date = new Date(time)
        let MM = date.getMonth() + 1
        MM = MM < 10 ? ('0' + MM) : MM
        let d = date.getDate()
        d = d < 10 ? ('0' + d) : d
        return MM + '-' + d
      },
      initAccCheck () {
        this.accCheck = undefined
      },
      initComCheck () {
        this.comCheck = undefined
      },

      getSellerInfo (row) {
        this.sellerInfo =
          {
            name: row.seller.name,
            contact: row.seller.contact,
            mobile: row.seller.mobile,
            qq: row.seller.qq,
            address: row.seller.address
          }
        this.visibleInf = true
      },
      infoFormat (info) {
        if (info === undefined) {
          return '暂无'
        }
        return info
      }
    }
  }
</script>

<style lang="less" scoped>
  /deep/ .family input {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
  }

  /deep/ .family1 input {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 12px;
  }

  /deep/ .el-button {
    height: 32px !important;
    font-size: 13px;
    padding: 0 15px;
  }

  .scope {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /deep/ .buttonTable {
    width: 28px !important;
    height: 22px !important;
    font-size: 12px;
    padding: 0;
    margin: 0 5px;
  }

  .span {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
  }

  .span1 {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 12px;
  }

  .link {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 12px;
  }

  .spanPopo {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
  }

  /deep/ .buttonPopo {
    width: 40px !important;
    height: 28px !important;
    font-size: 12px;
    padding: 0;
    margin: 0 0 0 10px;
  }

  /deep/ .el-table td,
  /deep/ .el-table th.is-leaf {
    border-bottom: 0.5px solid #EBEEF5 !important;
  }

  /deep/ .el-table--border th,
  /deep/ .el-table--border th.gutter:last-of-type {
    border-bottom: 0.5px solid #EBEEF5 !important;
  }

  /deep/ .el-table--border td,
  /deep/ .el-table--border th {
    border-right: 0.5px solid #EBEEF5 !important;
  }

  /deep/ .el-dialog__header {
    display: none;
  }

  /deep/ .el-dialog__body {
    padding: 0;
  }

  /deep/ .dialogPay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 !important;
    width: 350px;
    height: 189px;
  }

  /deep/ .dialogCode {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 !important;
    width: 470px;
    height: 401px;
  }

  /deep/ .dialogImg {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 !important;
    width: 655px;
    height: 560px;
  }

  .el-input-number {
    width: 85px !important;
  }

  /deep/ .el-input-number .el-input__inner {
    text-align: left;
  }

  .comment {
    color: #EB6100;
    font-size: 12px;
    line-height: 16px;
    margin-top: 3px;
    -webkit-transform-origin-x: 0;
    -webkit-transform: scale(0.85);
  }

  .scomment {
    color: #409EFF;
    font-size: 12px;
    line-height: 16px;
    margin-top: 3px;
    -webkit-transform-origin-x: 0;
    -webkit-transform: scale(0.85);
  }

  /deep/ .dialogInf {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 !important;
    width: 370px;
    height: 250px;
  }

  .title {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 12px;
    width: 55px;
    color: #409EFF;
  }

  .cont {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 12px;
    width: 255px;
  }

  .infodiv {
    display: flex;
    justify-content: start;
    align-items: center;
    height: 40px;
  }

  .el-divider--horizontal {
    margin: 1px 0;
    height: 1px;
  }

  .el-divider {
    background-color: #0000000c
  }

  .el-divider--vertical {
    margin: 0 20px;
  }

</style>
